const cfop_compra = [
    { code: "5401", description: "Venda de Produção do Estabelecimento" },
    { code: "5403", description: "Venda de Mercadoria Adquir. de Terceiros" },
    { code: "5405", description: "Devolução de Compra p/ Industrialização" },
    { code: "6401", description: "Devolução de Compra p/ Comercialização" },
    { code: "6403", description: "Venda de Prod. Estabelec. c/ Subst. Tributária" },
    { code: "6404", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
    { code: "5101", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
    { code: "5102", description: "Devol. Compra p/ Comercial. c/ Subst. Tributária" },
    { code: "6101", description: "Devolução de Compra de Material de Uso ou Consumo" },
    { code: "6102", description: "Transferência de Material p/ Uso ou Consumo" },
];


// const cfop_compra = [
//     { code: "5101", description: "Venda de Produção do Estabelecimento" },
//     { code: "5102", description: "Venda de Mercadoria Adquir. de Terceiros" },
//     { code: "5201", description: "Devolução de Compra p/ Industrialização" },
//     { code: "5202", description: "Devolução de Compra p/ Comercialização" },
//     { code: "5401", description: "Venda de Prod. Estabelec. c/ Subst. Tributária" },
//     { code: "5403", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
//     { code: "5405", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
//     { code: "5411", description: "Devol. Compra p/ Comercial. c/ Subst. Tributária" },
//     { code: "5556", description: "Devolução de Compra de Material de Uso ou Consumo" },
//     { code: "5557", description: "Transferência de Material p/ Uso ou Consumo" },
//     { code: "5910", description: "5910 Bonificacao" },
//     { code: "5911", description: "Remessa de Amostra Grátis" },
//     { code: "5929", description: "Lançam. Efetuado de Emissão de NF também Reg. no ECF" },
//     { code: "6101", description: "Venda de Produção do Estabelecimento" },
//     { code: "6102", description: "Venda de Mercadoria Adquir. de Terceiros" },
//     { code: "6108", description: "NF FILIAL SP" },
//     { code: "6201", description: "Devolução de Compra p/ Industrialização" },
//     { code: "6202", description: "Devolução de Compra p/ Comercialização" },
//     { code: "6401", description: "Venda de Prod. Estabelec. c/ Subst. Tributária" },
//     { code: "6403", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
//     { code: "6404", description: "Venda Mercadoria Adquirida c/ Subst. Tributária" },
//     { code: "6411", description: "Devol. Compra p/ Comercial. c/ Subst. Tributária" },
//     { code: "6556", description: "Devolução de Compra de Material de Uso ou Consumo" },
//     { code: "6557", description: "Transferência de Material p/ Uso ou Consumo" },
//     { code: "6603", description: "Ressarcimento de ICMS retido por substituição tributária" },
//     { code: "6911", description: "Remessa de Amostra Grátis" },
//     { code: "6929", description: "Lançam. Efetuado de Emissão de NF também Reg. no ECF" },
// ];


// const cfop_entrada = [
//     { code: "1101", description: "Compra para Industrialização", number: 1 },
//     { code: "1102", description: "Compra para Comercialização", number: 2 },
//     { code: "1126", description: "Compra p/ Utiliz. Prestação de Serviços", number: 3 },
//     { code: "1151", description: "Transferênia para Industrialização", number: 4 },
//     { code: "1152", description: "Transferênia para Comercialização", number: 5 },
//     { code: "1201", description: "Devolução Venda de Produção do Estabelecimento", number: 6 },
//     { code: "1202", description: "Devolução de Venda de Mercadoria", number: 7 },
//     { code: "1352", description: "Aquisição Serv. Transp. por Estab. Industrial", number: 8 },
//     { code: "1353", description: "Aquisição Serv. Transp. por Estab. Comercial", number: 9 },
//     { code: "1401", description: "Compra p/ Indust. Merc. c/ Subst. Tributária", number: 10 },
//     { code: "1403", description: "Compra p/ Comercio. Merc. c/ Subst. Tributária", number: 11 },
//     { code: "1407", description: "Compra p/ Uso ou Consumo c/ Subst. Tributária", number: 12 },
//     { code: "1411", description: "Devol. Venda Mercad. Adquir. c/ Subst. Tributária", number: 13 },
//     { code: "1556", description: "Compra de Material p/ Uso ou Consumo", number: 14 },
//     { code: "2101", description: "Compra para Industrialização", number: 15 },
//     { code: "2102", description: "Compra para Comercialização", number: 16 },
//     { code: "2126", description: "Compra p/ Utiliz. Prestação de Serviços", number: 17 },
//     { code: "2151", description: "Transferênia para Industrialização", number: 18 },
//     { code: "2152", description: "Transferênia para Comercialização", number: 19 },
//     { code: "2201", description: "Devolução Venda de Produção do Estabelecimento", number: 20 },
//     { code: "2202", description: "Devolução de Venda de Mercadoria", number: 21 },
//     { code: "2352", description: "Aquisição Serv. Transp. por Estab. Industrial", number: 22 },
//     { code: "2353", description: "Aquisição Serv. Transp. por Estab. Comercial", number: 23 },
//     { code: "2401", description: "Compra p/ Indust. Merc. c/ Subst. Tributária", number: 24 },
//     { code: "2403", description: "Compra p/ Comercio. Merc. c/ Subst. Tributária", number: 25 },
//     { code: "2407", description: "Compra p/ Uso ou Consumo c/ Subst. Tributária", number: 26 },
//     { code: "2411", description: "Devol. Venda Mercad. Adquir. c/ Subst. Tributária", number: 27 },
//     { code: "2556", description: "Compra de Material p/ Uso ou Consumo", number: null },
// ];



export  {cfop_compra};